import React from "react"
import Typewriter from "typewriter-effect"
import styled from "styled-components"
import { FormattedHTMLMessage, navigate, useIntl } from "gatsby-plugin-intl"

import Collecting from "../assets/Collecting.svg"
import StartupLife from "../assets/StartupLife.svg"

import Footer from "../components/Footer"
import Layout from "../components/Layout"
import Meta from "../components/Meta"
import Navbar from "../components/Navbar"
import ProductCard from "../components/molecules/ProductCard"
import Statement from "../components/molecules/Statement"
import devices from "../helpers/devices"
import { Button } from "../components/simpa-design-kit"
import { H2, HeroP } from "../components/atoms/ResTypography"
import { PageProps } from "../helpers/props"

const SectionHero = styled.section`
  .Simpa {
    h1 {
      color: var(--primary-color);
      font-size: 2.125rem;
      font-weight: 600;

      @media ${devices.tablet} {
        font-size: 3.5rem;
      }

      @media ${devices.desktop} {
        font-size: 4rem;
      }
    }

    div.Typewriter {
      position: relative;
      top: -1rem;

      display: inline;

      @media ${devices.tablet} {
        top: -2rem;
      }

      span {
        font-size: 2.125rem;

        @media ${devices.tablet} {
          font-size: 3.5rem;
        }

        @media ${devices.desktop} {
          font-size: 4rem;
        }
      }
    }
  }

  .hero-p {
    @media ${devices.desktop} {
      margin-right: 6rem;
    }
  }
`

const StyledTczButton = styled.div`
  button {
    background-color: var(--color-tcz-primary);

    &:active,
    &:hover,
    &:focus {
      background-color: #cc0800;
    }
  }
`

const IndexPage = ({ location }: PageProps) => {
  const intl = useIntl()
  const title = intl.formatMessage({ id: "Home" })

  function onCallToActionClick() {
    const e = document.querySelector("#feedback")
    if (e) e.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <Layout>
      <Meta pathname={location.pathname} title={title} />
      <Navbar isBrandVisible={false} isFixedTop={false} />
      <SectionHero className="section is-clipped" id="hero">
        <div className="container">
          <div className="columns">
            <div className="column is-two-thirds-tablet is-three-fifths-desktop">
              <div className="Simpa">
                <h1>Simpa.</h1>
                <Typewriter
                  onInit={() => {}}
                  options={{
                    strings: [
                      "Artificial Intelligence",
                      "Design",
                      "Development",
                      "Digital Transformation",
                      "Marketing",
                      "Operations",
                    ],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </div>
              <HeroP className="hero-p">
                {intl.formatMessage({ id: '"Simpa" happens - starting point!' })}
                <br />
                <FormattedHTMLMessage id="h_do_it_simpa" />
              </HeroP>
              <HeroP className="hero-p">
                <FormattedHTMLMessage id="h_made_in_europe" />
              </HeroP>
              <Button className="primary" onClick={onCallToActionClick}>
                {intl.formatMessage({ id: "Discover" })}
              </Button>
            </div>
            <div className="column is-6 is-hidden-mobile">
              <img alt="" className="il" src={StartupLife} />
            </div>
          </div>
        </div>
      </SectionHero>
      <section className="section is-clipped" id="feedback" style={{ backgroundColor: "#edf7f0" }}>
        <div className="container">
          <Statement
            leftIcon={<img alt="" src={Collecting} />}
            title={<FormattedHTMLMessage id="h_constant_feedback_all" />}
            message={[
              intl.formatMessage({ id: "Disruption starts with unhappy customers, not technology." }),
              <br key="f1" />,
              intl.formatMessage({ id: "The market changes and so should we; listen, test and take action." }),
              <br key="f2" />,
              intl.formatMessage({ id: "We are here to get inspired and take a journey together." }),
            ]}
          />
        </div>
      </section>
      <section className="section is-clipped" id="products">
        <div className="container">
          <div className="columns">
            <div className="column has-text-centered">
              <H2 className="title">{intl.formatMessage({ id: "How technology solves real common problems." })}</H2>
              <p>{intl.formatMessage({ id: "p_looking_for_innovation" })}</p>
            </div>
          </div>
          <div className="columns is-centered">
            <div className="column is-10-widescreen">
              <div className="columns">
                <div className="column is-one-third-fullhd"></div>
                <div className="column is-one-third-fullhd">
                  <ProductCard
                    title="Cocktail Zone"
                    color="var(--color-tcz-primary)"
                    body={<FormattedHTMLMessage id="h_cocktails_to_make" tagName="p" />}
                    cta={
                      <StyledTczButton>
                        <Button
                          className="small is-centered-on-mobile is-centered-on-tablet"
                          onClick={() => navigate("/products/cocktail-zone/")}
                        >
                          {intl.formatMessage({ id: "Learn more" })}
                        </Button>
                      </StyledTczButton>
                    }
                  />
                </div>
                <div className="column is-one-third-fullhd"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  )
}

export default IndexPage
