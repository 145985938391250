import React from "react"
import styled from "styled-components"

const Card = styled.div`
  display: flex;
  height: 100%;
  border-radius: 4px;

  .card-content {
    display: flex;
    width: 100%;

    .content {
      position: relative;
      flex: 1;

      .title.is-3 {
        margin-bottom: 1rem;
        font-size: 1.95rem;
      }

      .cta {
        position: absolute;
        bottom: 0;
      }
    }
  }
`

interface ProductCardProps {
  body: React.ReactNode
  className?: string
  color?: string
  cta?: React.ReactNode
  title?: React.ReactNode
}

const ProductCard: React.FunctionComponent<ProductCardProps> = ({ body, className, color, cta, title }) => (
  <Card className={`card ${className}`}>
    <div className="card-content">
      <div className="content">
        {title ? (
          <h3 className="title is-3 has-text-centered" style={{ color }}>
            {title}
          </h3>
        ) : (
          undefined
        )}
        <div className="card-body" style={cta ? { marginBottom: "3.25rem" } : {}}>
          {body}
        </div>
        {cta ? <div className="cta">{cta}</div> : undefined}
      </div>
    </div>
  </Card>
)

ProductCard.defaultProps = {
  className: "",
  color: "inherit",
} as Partial<ProductCardProps>

export default ProductCard
